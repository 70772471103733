import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import Button from '@wtag/rcl-button';
import Alert from 'sharedWebpack/Alert';
import { Link, QuantitySelector, I18nText } from '@wtag/react-comp-lib';
import { Menu } from '@wtag/rcl-menu';
import Icon from '@wtag/rcl-icon';
import Card from '@wtag/rcl-card';
import { searchFlights } from '../../actions/flight';
import SearchAccounts from '../../components/SearchAccounts';
import searchAccountsShape from '../../shapes/searchAccounts';
import availableChannelsShape from '../../shapes/availableChannels';
import noChannelSelected from '../../lib/helpers/noChannelSelected';
import AirlineSelection from './AirlineSelectionContainer';
import PreselectTravelers from '../PreselectTravelers';
import preparePreselectTravelers from '../PreselectTravelers/preparePreselectTravelers';
import useSearchFormContainer from '../../helpers/hooks/useSearchFormContainer';
import PreferredAirlineSwitcher from './PreferredAirlineSwitcher';
import AirportShape from '../shapes/AirportShape';
import getTripType from '../../lib/helpers/getTripType';
import { MULTICITY, ONEWAY, ROUNDTRIP } from '../../lib/helpers/tripTypes';
import { CUSTOM_PASSENGER_TYPE_CODES } from '../../../../shared/helpers/passengerTypes';
import MenuLabel from '../helpers/MenuLabel';
import AdvancedFlightForm from './AdvancedFlightForm';
import TripTypeButton from './TripTypeButton';
import FlightSearchFilter from './FlightSearchFilter';
import MenuLabelWithPreSelectText from '../MenuLabelWithPreSelectText';
import usePreselectTraveler from '../../helpers/hooks/usePreselectTraveler';
import shouldShowPreselectTravelerButton from '../shouldShowPreselectTravelerButton';

const TRIP_COUNT_ONE = 1;
const TRIP_COUNT_TWO = 2;
const TRIP_COUNT_THREE = 3;

const SearchFormComponent = props => {
  const {
    fields: {
      trips,
      adults,
      children,
      infants,
      flexible,
      accountSettings,
      airlinePreferences,
      nonStop,
      onlyFlexibleFlight,
      isShowTravelfusionAlert,
    },
    submitting,
    handleSubmit,
    cartHasItems,
    preselectTravelers,
    setIsCollapsed,
    forceAdvancedSearch,
    formWithoutChildren,
    resetApp,
    resetBookingAppContext,
    travelerLoggedIn,
    laymanMode,
    travelArrangerPresent,
    airportSuggestions,
    isMultiCitySelected,
    setIsMultiCitySelected,
    wtsEnabled,
    searchForm,
    advancedForm: isAdvancedForm,
    tripType,
    setTripType,
    isFromLastSearch,
    setIsFromLastSearch,
    canExcludeWtsContent,
    callbackParams,
    preselectDecision,
    updatePreselectTravelers,
  } = props;

  const showFlightFormContainer = useSearchFormContainer();
  const [isTravelerMenuVisible, setIsTravelerMenuVisible] = useState(false);
  const [isPreferedMenuVisible, setIsPreferedMenuVisible] = useState(false);
  const [isPreselectTravellerVisible, setIsPreselectTravellerVisible] = useState(false);
  const [passengerTypeCodes, setPassengerTypeCodes] = useState([]);
  const [adultCount, setAdultCount] = useState(1);
  const [passengerTypeCodeQuantity, setPassengerTypeCodeQunatity] = useState({});
  const preselectTravelersLength = preselectTravelers.length;
  const loggedInAsTravelArranger = travelerLoggedIn && travelArrangerPresent;
  const loggedOutWithEmptyCartAndForm = !travelerLoggedIn && !cartHasItems && formWithoutChildren;
  const isPreSelectTravelerButtonVisible = shouldShowPreselectTravelerButton(
    loggedInAsTravelArranger,
    loggedOutWithEmptyCartAndForm,
    forceAdvancedSearch,
    laymanMode,
    travelerLoggedIn,
  );

  usePreselectTraveler({
    adults: adults.value,
    children: children.value,
    infants: infants.value,
    laymanMode,
    travelerLoggedIn,
    preselectDecision,
    preselectTravelers,
    callbackParams,
    updatePreselectTravelers,
    shouldPreselect: isPreSelectTravelerButtonVisible,
  });

  const isTripTypeMultiCity = isMultiCitySelected || trips.length > TRIP_COUNT_TWO;
  const isChildOrInfantAdded = children.value > 0 || infants.value > 0;
  const isPtcSelected = Object.values(passengerTypeCodeQuantity).some(value => value > 0);
  const settingsPath = '/admin/passenger_type_codes'

  const onTravelerMenuOutsideClick = () => {
    setIsTravelerMenuVisible(isPreselectTravellerVisible);
  };

  const onPreferedMenuOutsideClick = () => {
    setIsPreferedMenuVisible(false);
  };
  const onTravelerMenuClick = () => setIsTravelerMenuVisible(prevState => !prevState);
  const onPrefferedMenuClick = () => setIsPreferedMenuVisible(prevState => !prevState);

  const getUpdatedTrips = currentTrips => {
    const tripCount = currentTrips.length;

    if (tripCount !== TRIP_COUNT_TWO) {
      return currentTrips;
    }

    const updatedTrips = [
      {
        originCode: currentTrips[0] ? currentTrips[0].origin.value : '',
        destinationCode: currentTrips[0] ? currentTrips[0].destination.value : '',
      },
      {
        originCode: currentTrips[tripCount - 1] ? currentTrips[tripCount - 1].origin.value : '',
        destinationCode: currentTrips[tripCount - 1]
          ? currentTrips[tripCount - 1].destination.value
          : '',
      },
    ];

    return updatedTrips;
  };


  const fetchPassengerTypeCodes = async () => {
    const { data } = await httpClient.get(
      routes.api.passengerTypeCodes.list(),
    );
    setPassengerTypeCodes(data);
  };

  const updatePassengerTypeCodeQuantity=(val,code)=>{
    setPassengerTypeCodeQunatity(prev => ({ ...prev, [code]: val }));

    if (val > 0) {
      adults.onChange(val);
      setAdultCount(0);
    }
    else {
      setAdultCount(1)
    }
  }

  const updateAdultCount = (val) => {
    setAdultCount(val);
    adults.onChange(val);
  }

  const selectedPtcCode = () => {
    const entry = Object.entries(passengerTypeCodeQuantity).find(([, value]) => value > 0);
    return entry ? entry[0] : null;
  };

  const getPtcSupportedChannels = () => {
    const allPassengerTypeCodes = CUSTOM_PASSENGER_TYPE_CODES;

    if (!isPtcSelected) {
      return null;
    }

    const selectedPtc = allPassengerTypeCodes.find(
      (ptc) => ptc.code === selectedPtcCode()
    );

    if (!selectedPtc) {
      return null;
    }

    return selectedPtc.suppliers;
  };

  const isAnyUnsupportedChannelForPtcSelected = () => {
    if (!isPtcSelected || laymanMode) {
      return false;
    }
  
    const supportedChannels = new Set(getPtcSupportedChannels());
  
    return Object.entries(accountSettings).some(([channelKey, channel]) =>
      !supportedChannels.has(channelKey) && channel.value?.length > 0
    );
  };

  

  useEffect(() => {
    fetchPassengerTypeCodes();
  }, []);

  useEffect(() => {
    setTripType(
      trips.length > TRIP_COUNT_TWO || isMultiCitySelected
        ? MULTICITY
        : getTripType(getUpdatedTrips(trips)),
    );
  });

  useEffect(() => {
    if (isFromLastSearch) {
      if (isTripTypeMultiCity) setIsMultiCitySelected(false);
      setIsFromLastSearch(false);
    } else {
      const isTripTypeMultiCitySelected =
        isMultiCitySelected && trips[0].origin.value === '' && trips[1].origin.value === '';

      setTripType(isTripTypeMultiCitySelected ? MULTICITY : getTripType(getUpdatedTrips(trips)));
    }
  }, [trips]);

  const tripDate = index => {
    if (trips[index]) {
      return trips[index].date.value;
    }
    return undefined;
  };

  const lastTripDate = currentIndex => {
    const index = currentIndex - 1;
    return tripDate(index);
  };

  const minDate = currentIndex => {
    const previousIndex = currentIndex - 1;
    if (currentIndex === 0) {
      return moment(new Date()).add(-1, 'days');
    }

    if (trips[previousIndex] && !trips[previousIndex].date.value) {
      return minDate(previousIndex);
    }
    return lastTripDate(currentIndex);
  };

  const isChannelSelected = (channelName, passedAccountSettings) => {
    if (laymanMode || props.exchange.enabled) {
      return props.availableChannels.flights.includes(channelName);
    }
    if (
      passedAccountSettings &&
      passedAccountSettings[channelName] &&
      passedAccountSettings[channelName].value &&
      passedAccountSettings[channelName].value.length > 0
    ) {
      return true;
    }
    return false;
  };

  const welltravelChannel = props.fields.welltravelChannel || { value: false };

  const isChannelMissingAlertVisible =
    !props.exchange.enabled &&
    noChannelSelected(laymanMode, props.availableChannels, accountSettings, 'flights') &&
    !welltravelChannel.value;

  const disableAddTripButton = (tripLength, passedAccountSettings, passedFlexible) => {
    const isSabreSelected = isChannelSelected('sabre', passedAccountSettings);
    if (isSabreSelected && passedFlexible.value === true && tripLength >= TRIP_COUNT_TWO) {
      return true;
    }

    const isAmadeusSelected = isChannelSelected('amadeus', passedAccountSettings);
    if (isAmadeusSelected) {
      if ((passedFlexible.value === '' || passedFlexible.value === false) && tripLength >= 6) {
        return true;
      } else if (passedFlexible.value === true && tripLength >= TRIP_COUNT_TWO) {
        return true;
      }
    }

    const isBritishAirwaysSelected = isChannelSelected('british_airways', passedAccountSettings);
    if (isBritishAirwaysSelected && tripLength >= TRIP_COUNT_TWO) {
      return true;
    }

    const isIberiaSelected = isChannelSelected('iberia', passedAccountSettings);
    if (isIberiaSelected && tripLength >= TRIP_COUNT_TWO) {
      return true;
    }
    if (tripLength >= TRIP_COUNT_TWO) {
      return true;
    }

    return false;
  };

  const isTravelFusionSelected = isChannelSelected('travelfusion', accountSettings);

  const itinerarySearch = props.fields.itinerarySearch || {
    value: false,
  };

  const isMultiCityButtonDisabled = (passedAccountSettings, passedFlexible) => {
    const isSabreSelected = isChannelSelected('sabre', passedAccountSettings);

    if (isSabreSelected && passedFlexible && passedFlexible.value) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    const isAmadeusSelected = isChannelSelected('amadeus', passedAccountSettings);

    if (isAmadeusSelected && passedFlexible) {
      if (passedFlexible.value === '' || passedFlexible.value === false) {
        return {
          disabled: false,
          maxTripLength: 6,
        };
      } else if (passedFlexible && passedFlexible.value) {
        return {
          disabled: true,
          maxTripLength: TRIP_COUNT_TWO,
        };
      }
    }

    const isBritishAirwaysSelected = isChannelSelected('british_airways', passedAccountSettings);

    if (isBritishAirwaysSelected) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    const isIberiaSelected = isChannelSelected('iberia', passedAccountSettings);
    if (isIberiaSelected) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    if (isTravelFusionSelected && itinerarySearch.value) {
      return { disabled: true };
    }

    return {
      disabled: false,
    };
  };

  const isMultiCityAvailable = !isMultiCityButtonDisabled(trips.length, accountSettings, flexible)
    .disabled;

  const addTrip = () => {
    let defaultValues = props.defaultSearchParams.trips[0];
    const lastTrip = trips.slice(-1)[0];
    const { origin, destination, cabinClass, departureTime } = lastTrip;

    if (!(isTravelFusionSelected && itinerarySearch.value) || tripType === ONEWAY) {
      if (lastTrip) {
        defaultValues = {
          origin: destination.value,
          cabinClass: cabinClass.value,
          departureTime: departureTime.value,
          bookingClass: [],
          alliancePreferences: [],
        };

        if (trips.length === TRIP_COUNT_ONE) {
          defaultValues.destination = origin.value;
        }
      }

      if (tripType === ROUNDTRIP) {
        setTripType(MULTICITY);
        setIsMultiCitySelected(true);
        trips.addField(defaultValues);
      } else {
        trips.addField(defaultValues);
      }
    } else {
      isShowTravelfusionAlert.onChange(true);
    }
  };

  const switchToRoundTrip = async () => {
    await setIsMultiCitySelected(false);
    await setTripType(ROUNDTRIP);

    let count = trips.length;
    if (count < TRIP_COUNT_TWO) {
      while (count !== TRIP_COUNT_TWO) {
        addTrip();
        count += 1;
      }
    } else if (count >= TRIP_COUNT_TWO) {
      const [firstTrip] = trips;

      trips[1].origin.onChange(firstTrip.destination.value);
      trips[1].destination.onChange(firstTrip.origin.value);

      while (count !== TRIP_COUNT_TWO) {
        trips.removeField();
        count -= 1;
      }
    }
  };

  if (
    isTravelFusionSelected &&
    itinerarySearch.value &&
    tripType === MULTICITY &&
    !isShowTravelfusionAlert.value
  ) {
    isShowTravelfusionAlert.onChange(true);
    switchToRoundTrip();
  }

  const disableSubmitButton = (passedTrips, passedAccountSettings, passedFlexible) => {
    const tripLength = passedTrips.length;
    const isSabreSelected = isChannelSelected('sabre', passedAccountSettings);
    if (isSabreSelected && passedFlexible.value === true && trips.length > TRIP_COUNT_TWO) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    const isAmadeusSelected = isChannelSelected('amadeus', passedAccountSettings);
    if (isAmadeusSelected) {
      if ((passedFlexible.value === '' || passedFlexible.value === false) && tripLength > 6) {
        return {
          disabled: true,
          maxTripLength: 6,
        };
      } else if (passedFlexible.value === true && tripLength > TRIP_COUNT_TWO) {
        return {
          disabled: true,
          maxTripLength: TRIP_COUNT_TWO,
        };
      }
    }

    const isBritishAirwaysSelected = isChannelSelected('british_airways', passedAccountSettings);
    if (isBritishAirwaysSelected && tripLength > TRIP_COUNT_TWO) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    const isIberiaSelected = isChannelSelected('iberia', passedAccountSettings);
    if (isIberiaSelected && tripLength > TRIP_COUNT_TWO) {
      return {
        disabled: true,
        maxTripLength: TRIP_COUNT_TWO,
      };
    }

    if (isTravelFusionSelected) {
      if (
        (tripLength === TRIP_COUNT_TWO &&
          passedTrips[0] &&
          passedTrips[0].origin.value !== '' &&
          passedTrips[0].destination.value !== '' &&
          passedTrips[1] &&
          passedTrips[1].origin.value !== '' &&
          passedTrips[1].destination.value !== '' &&
          tripType === MULTICITY) ||
        tripLength > TRIP_COUNT_TWO
      ) {
        return {
          disabled: true,
        };
      }
    }

    return {
      disabled: false,
    };
  };

  const canAddNewTrip = !isMultiCityAvailable;

  const isAddTripDisabled =
    itinerarySearch.value &&
    ((disableAddTripButton(trips.length, accountSettings, flexible) && canAddNewTrip) ||
      isTravelFusionSelected);

  const switchToOneWay = () => {
    setIsMultiCitySelected(false);

    let count = trips.length;
    while (count > TRIP_COUNT_ONE) {
      trips.removeField();
      count -= 1;
    }
  };

  const switchToMultiCity = async () => {
    await setIsMultiCitySelected(true);
    await setTripType(MULTICITY);

    let count = trips.length;

    if (count < TRIP_COUNT_THREE) {
      while (count !== TRIP_COUNT_THREE) {
        addTrip();
        count += 1;
      }
    }

    props.setAdvancedForm(true);
  };

  const validateOnSubmit = (values, dispatch, ownProps) => {
    const errors = {};
    let error;
    let hasError = false;
    const airportSuggestionList = props.airportSuggestionList;

    errors.trips = values.trips.map(trip => {
      error = {};
      if (airportSuggestionList instanceof Array) {
        if (!airportSuggestionList.includes(trip.origin)) {
          if (!hasError) {
            hasError = true;
          }
          error = {
            origin: <I18nText id="ibe.validations.unknown_airport" />,
          };
        }
        if (!airportSuggestionList.includes(trip.destination)) {
          if (!hasError) {
            hasError = true;
          }
          error = {
            destination: <I18nText id="ibe.validations.unknown_airport" />,
            ...error,
          };
        }
      }
      return error;
    });

    return new Promise((resolve, reject) => {
      if (hasError) {
        reject(errors);
      } else {
        dispatch(
          searchFlights(
            {
              ...values,
              includeWelltravelResults: welltravelChannel.value && !laymanMode && !isPtcSelected,
              currency: ownProps.currency,
              travelerLoggedIn: ownProps.travelerLoggedIn,
              preselectTravelers: preparePreselectTravelers(preselectTravelers),
              passengerTypeCode: selectedPtcCode(),
            },
            ownProps.callbackParams,
          ),
        );
        resolve();
        setIsCollapsed();
      }
    });
  };

  const isDisableSubmitButton = disableSubmitButton(trips, accountSettings, flexible);
  const travelersFieldError =
    (adults && adults.error) || (children && children.error) || (infants && infants.error);
  const minNumberOfAdults = 1;
  const maxNumberOfAdults = 9;
  const totalNumberOfTravelers = adults.value + children.value + infants.value;

  const preloadPreferences = () => {
    const carrierCodes = props.globalPolicyPreferences.airlines;
    if (carrierCodes.length > 0) {
      airlinePreferences.carriers.onChange(carrierCodes);
      airlinePreferences.type.onChange('exclusive');
    }
  };

  const handleChangeAdvanceSearch = () => {
    props.setAdvancedForm(prevState => !prevState);
  };

  const removeTrip = async index => {
    if (isTripTypeMultiCity) await setIsMultiCitySelected(false);
    // If we have 2 trips and type is multiCity it will set tripType as roundTrip.
    if (trips.length === TRIP_COUNT_TWO && tripType === MULTICITY) {
      setTripType(ROUNDTRIP);
      if (isTripTypeMultiCity) setIsMultiCitySelected(false);
    } else {
      trips.removeField(index);
    }
  };

  useEffect(() => {
    preloadPreferences();
  }, []);

  useEffect(() => {
    if (!props.searchForm) {
      props.initializeForm({
        accountSettings: props.accountSettings,
        ...props.defaultSearchParams,
        ...props.cartOverrides,
      });
      preloadPreferences();
    }
  }, [props.searchForm]);

  const isSearchDisabled =
    !!submitting || isAnyUnsupportedChannelForPtcSelected() ||
    (!props.exchange.enabled &&
      noChannelSelected(laymanMode, props.availableChannels, accountSettings, 'flights') &&
      !welltravelChannel.value) ||
    (itinerarySearch.value && isDisableSubmitButton.disabled);

  const overflowTripsInLaymanMode = laymanMode && !!trips && trips.length >= 4;

  const dropdownBar = trips.length > 0 && (
    <div className="col-grid col-bleed wrap flight-form__switchers-and-dropdowns align-center">
      <div className="flight-form__trip-type-buttons">
        <TripTypeButton tripType={ONEWAY} isActive={tripType === ONEWAY} onClick={switchToOneWay} />
        <TripTypeButton
          tripType={ROUNDTRIP}
          isActive={tripType === ROUNDTRIP}
          onClick={switchToRoundTrip}
        />
        {isMultiCityAvailable && (
          <TripTypeButton
            tripType={MULTICITY}
            isActive={tripType === MULTICITY}
            onClick={switchToMultiCity}
          />
        )}
      </div>
      <div className="flight-form__vertical-divider" />
      <div className="flight-form__drop-downs">
        {!props.exchange.enabled && (
          <div className="search-menu__container search-menu__container--traveller-amount">
            <Menu
              className="search-menu__container--traveller-amount-dropdown"
              isVisible={isTravelerMenuVisible}
              size="medium"
              popOverDirection="bottom-left"
              onOutsideClick={onTravelerMenuOutsideClick}
              label={
                <MenuLabel
                  icon={<Icon name="adult" className="search-menu__icon" />}
                  onClick={onTravelerMenuClick}
                  label={
                    <MenuLabelWithPreSelectText
                      totalNumberOfTravelers={totalNumberOfTravelers}
                      preselectTravelersLength={preselectTravelersLength}
                    />
                  }
                  isError={travelersFieldError}
                  isVisible={isTravelerMenuVisible}
                />
              }
            >
              <Fragment>
                <div className="search-menu-item__container">
                  <div className="hotel-form__preselect-header">
                    <div className="hotel-form__traveller-title preselect_button">
                      {I18n.t('admin.components.organizations.overview.tabs.traveller.title')}
                    </div>
                    {isPreSelectTravelerButtonVisible && !isPtcSelected && (
                      <PreselectTravelers
                        type="flight"
                        setIsPreselectTravellerVisible={setIsPreselectTravellerVisible}
                        onAdultChange={adults.onChange}
                        onChildrenChange={children.onChange}
                        onInfantChange={infants.onChange}
                        selectedTotalNumberOfTravelers={totalNumberOfTravelers}
                      />
                    )}
                  </div>
                  <div className="search-menu-item__specifier search-menu-item__specifier--add-padding-bottom">
                    <div className="search-menu-item__label">
                      {I18n.t('components.ibe.search_form.flight.label.adults')}
                      <div className="search-hints">
                        {I18n.t('components.ibe.search_form.flight.label.adults_age_range')}
                      </div>
                    </div>
                    <QuantitySelector
                      value={adultCount}
                      onChange={(val) => updateAdultCount(val)}
                      disabled={submitting || cartHasItems || isPtcSelected}
                      minValue={minNumberOfAdults}
                      maxValue={maxNumberOfAdults}
                    />
                  </div>
                  <div className="search-menu-item__specifier search-menu-item__specifier--add-padding-bottom">
                    <div className="search-menu-item__label">
                      {I18n.t('components.ibe.search_form.flight.label.children')}
                      <div className="search-hints">
                        {I18n.t('components.ibe.search_form.flight.label.children_age_range')}
                      </div>
                    </div>
                    <QuantitySelector {...children} disabled={submitting || cartHasItems || isPtcSelected} />
                  </div>
                  <div className="search-menu-item__specifier">
                    <div className="search-menu-item__label">
                      {I18n.t('components.ibe.search_form.flight.label.infants')}
                      <div className="search-hints">
                        {I18n.t('components.ibe.search_form.flight.label.infants_age_range')}
                      </div>
                    </div>
                    <QuantitySelector {...infants} disabled={submitting || cartHasItems || isPtcSelected} />
                  </div>
                  <div className="search-traveler-note search-menu-item__specifier--add-padding-bottom">
                    {I18n.t('components.ibe.search_form.traveler_note')}
                  </div>
                  {passengerTypeCodes &&
                    passengerTypeCodes.map(({name,code}) => (
                      <div className="search-menu-item__specifier search-menu-item__specifier--add-padding-bottom" key={code}>
                        <div className="search-menu-item__label">
                          {`${name} (${code})`}
                        </div>
                        <QuantitySelector 
                          value={passengerTypeCodeQuantity[code]}
                          maxValue={maxNumberOfAdults}
                          disabled={submitting || cartHasItems || isChildOrInfantAdded || (isPtcSelected && selectedPtcCode() !== code)}
                          onChange={(val) => updatePassengerTypeCodeQuantity(val,code)}
                        />
                      </div>
                    ))
                  }
                  {!laymanMode && (
                    <div className="search-traveler-note search-menu-item__specifier">
                      <div>
                        {I18n.t('components.ibe.search_form.ptc_note')}
                        <a href={settingsPath}>Settings</a>
                      </div>
                    </div>
                  )}

                </div>
                {travelersFieldError &&
                  travelersFieldError.map(error => (
                    <li key={error}>
                      <Alert
                        className="search-form-alert__traveler-counts search-menu-item__error"
                        hideClose={true}
                        isVisible={!!travelersFieldError}
                        type="danger"
                      >
                        {error}
                      </Alert>
                    </li>
                  ))}
                <Alert
                  className="search-menu-item__error"
                  hideClose={true}
                  isVisible={cartHasItems}
                  type="warning"
                >
                  {I18n.t('components.ibe.search_form.error.change_number_of_travelers')}
                </Alert>
              </Fragment>
            </Menu>
          </div>
        )}
        {props.searchInputConfiguration.showAirlinePreference && (
          <div className="search-menu__container search-menu__container--preferred-airline">
            <Menu
              className="search-menu__container--preferred-airline-dropdown"
              isVisible={isPreferedMenuVisible}
              size="medium"
              popOverDirection="bottom-right"
              onOutsideClick={onPreferedMenuOutsideClick}
              label={
                <MenuLabel
                  onClick={onPrefferedMenuClick}
                  label={I18n.t('components.ibe.search_form.flight.label.airline_preferences')}
                  isVisible={isPreferedMenuVisible}
                />
              }
            >
              <div className="search-menu-item__container">
                <PreferredAirlineSwitcher
                  onPreferredChange={airlinePreferences.type.onChange}
                  value={airlinePreferences.type.value}
                />

                <div className="col-12 col-bleed">
                  <AirlineSelection onAirlinesSelect={airlinePreferences.carriers.onChange} />
                </div>
              </div>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );

  const actionBars = trips.length > 0 && (
    <Fragment>
      <Alert
        className="search-form-alert__travelfusion"
        isVisible={isShowTravelfusionAlert && isShowTravelfusionAlert.value}
        type="warning"
        onClose={() => isShowTravelfusionAlert.onChange(false)}
      >
        {laymanMode ? (
          <I18nText id="ibe.search_form.flight.alert.travelfusion_travel_app" />
        ) : (
          <I18nText id="ibe.search_form.flight.alert.travelfusion" />
        )}
      </Alert>
      <Alert
        className="search-form-alert__trip-length"
        hideClose={true}
        isVisible={
          itinerarySearch.value && isDisableSubmitButton.disabled && tripType === MULTICITY
        }
        type="warning"
      >
        {[
          isDisableSubmitButton.maxTripLength &&
            I18n.t('components.ibe.search_form.trip_length', {
              count: isDisableSubmitButton.maxTripLength,
            }),
        ]}
      </Alert>
      {laymanMode && (
        <div
          className={classNames({
            'flight-form__search-filter': laymanMode,
            'flight-form__search-filter--advanced': isAdvancedForm && laymanMode,
          })}
        >
          <FlightSearchFilter
            searchInputConfiguration={props.searchInputConfiguration}
            flexible={flexible}
            onlyFlexibleFlight={onlyFlexibleFlight}
            exchange={props.exchange}
            itinerarySearch={itinerarySearch}
            welltravelChannel={welltravelChannel}
            wtsEnabled={wtsEnabled}
            nonStop={nonStop}
            laymanMode={laymanMode}
          />
        </div>
      )}
      <div className="col-grid col-bleed-x direction-row justify-space-between flight-form__action-bars">
        {laymanMode && (
          <Link onClick={handleChangeAdvanceSearch} type="default" modifier="default">
            <div
              className={classNames(
                'col-grid col-bleed direction-row align-center flight-form__advanced-search',
                {
                  'flight-form__advanced-search--active': isAdvancedForm,
                },
              )}
            >
              <div className="flight-form__advanced-search--button-text">
                {I18n.t(
                  `components.ibe.search_form.flight.label.${
                    isAdvancedForm ? 'hide_advance_search' : 'advance_search'
                  }`,
                )}
              </div>
              <Icon name={isAdvancedForm ? 'iconUpChevron' : 'iconDownChevron'} size="tiny" />
            </div>
          </Link>
        )}
        <div
          className={classNames('d-flex direction-column row-gap-24', {
            'w-100': !laymanMode,
            'align-end': laymanMode,
          })}
        >
          <Link
            className="flight-form__add-trips"
            onClick={addTrip}
            type="default"
            modifier="default"
            disabled={isAddTripDisabled}
          >
            <div className="col-grid col-bleed direction-row">
              <Icon
                name="add"
                size="tiny"
                color="tertiary"
                className="flight-form__add-trips-button-icon"
              />
              <div className="flight-form__add-trips-button-text">
                {I18n.t('components.ibe.search_form.flight.label.add_trips')}
              </div>
            </div>
          </Link>
          {!laymanMode && (
            <FlightSearchFilter
              searchInputConfiguration={props.searchInputConfiguration}
              flexible={flexible}
              onlyFlexibleFlight={onlyFlexibleFlight}
              exchange={props.exchange}
              itinerarySearch={itinerarySearch}
              welltravelChannel={welltravelChannel}
              wtsEnabled={wtsEnabled}
              nonStop={nonStop}
              laymanMode={laymanMode}
              canExcludeWtsContent={canExcludeWtsContent}
              isPtcSelected={isPtcSelected}
            />
          )}
        </div>
      </div>
    </Fragment>
  );

  const formToShow = (
    <div
      className={classNames({
        'col-7 col-bleed': !laymanMode,
        'w-100 flight-form__advanced-card--travel-app': laymanMode,
        'flight-form__advanced-card--overflow-layman-mode': overflowTripsInLaymanMode,
      })}
    >
      <Card
        className={classNames('flight-form__advanced-card', {
          'flight-form__advanced-card--overflow': tripType === MULTICITY && !laymanMode,
          'flight-form__advanced-card__container': !laymanMode,
        })}
        title={I18n.t('components.ibe.search_form.flight.search.title')}
      >
        <AdvancedFlightForm
          trips={trips}
          searchInputConfiguration={props.searchInputConfiguration}
          airportSuggestions={airportSuggestions}
          actionBars={actionBars}
          dropdownBar={dropdownBar}
          tripType={tripType}
          onTripRemove={removeTrip}
          isAdvancedForm={isAdvancedForm}
          laymanMode={laymanMode}
        />
      </Card>
    </div>
  );

  const channelsToShow = !laymanMode && searchForm && (
    <Card className="col-5 flight-form__channel-card">
      <SearchAccounts
        exchangeEnabled={props.exchange.enabled}
        searchAccounts={props.searchAccounts.flights}
        selectedAccounts={accountSettings}
        disabled={false}
        selectAll={props.selectAll}
        toggleSelectAll={props.toggleSelectAll}
        selectGds={props.selectGds}
        toggleSelectGds={props.toggleSelectGds}
        selectDirect={props.selectDirect}
        toggleSelectDirect={props.toggleSelectDirect}
        fromFlight={true}
        isChannelMissingAlertVisible={isChannelMissingAlertVisible}
        accountSettings={props.searchForm.accountSettings}
        selectedPtc={selectedPtcCode()}
        isAnyUnsupportedChannelForPtcSelected={isAnyUnsupportedChannelForPtcSelected}
        isChannelSelected={isChannelSelected}
        ptcSupportedChannels={getPtcSupportedChannels()}
      />
    </Card>
  );

  return (
    <form
      onSubmit={handleSubmit(validateOnSubmit)}
      className={classNames('flight-form d-flex direction-column row-gap-20', {
        'flight-form__simple': laymanMode,
        'flight-form__show': showFlightFormContainer,
      })}
    >
      <div className="d-flex">
        {formToShow}
        {channelsToShow}
      </div>
      <div
        className={classNames('d-flex', {
          'flight-form__bottom-right-action-bar': !laymanMode,
          'flight-form__bottom-right-action-bar__travelapp': laymanMode,
        })}
      >
        <Button
          className="flight-form__search-button"
          size="normal"
          version="v2"
          label={I18n.t('components.ibe.search_form.flight.label.search')}
          primary={true}
          disabled={isSearchDisabled}
          submitType="submit"
        />
        <div className="flight-form__reset-button d-flex align-center">
          <Link
            onClick={() => {
              resetApp();
              resetBookingAppContext();
            }}
          >
            {I18n.t('components.ibe.search_form.flight.label.reset')}
          </Link>
        </div>
      </div>
    </form>
  );
};

SearchFormComponent.defaultProps = {
  searchAccounts: null,
  searchForm: null,
  searchInputConfiguration: {
    showDepartureTime: true,
    showPlusMinusThreeDays: true,
    showNonStop: true,
    showAirlinePreference: true,
    showOnlyFlexibleFlight: true,
    showBookingClass: true,
    showAlliancePreferences: true,
    showOnlyBagIncludedFlight: true,
    showExcludeCodeShareFlights: true,
    whitelistedAirports: [],
  },
  exchange: {
    enabled: false,
  },
  selectAll: true,
  accountSettings: {},
  setIsCollapsed: () => {},
  resetBookingAppContext: () => {},
  isMultiCitySelected: false,
  setIsMultiCitySelected: () => {},
  wtsEnabled: false,
  isFromLastSearch: false,
};

SearchFormComponent.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  airportSuggestionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableChannels: availableChannelsShape.isRequired,
  setAdvancedForm: PropTypes.func.isRequired,
  resetApp: PropTypes.func.isRequired,
  advancedForm: PropTypes.bool.isRequired,
  defaultSearchParams: PropTypes.shape().isRequired,
  cartOverrides: PropTypes.shape().isRequired,
  fields: PropTypes.shape().isRequired,
  submitting: PropTypes.bool.isRequired,
  cartHasItems: PropTypes.bool.isRequired,
  initializeForm: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  forceAdvancedSearch: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  searchForm: PropTypes.shape(),
  searchAccounts: searchAccountsShape,
  accountSettings: searchAccountsShape.flights,
  searchInputConfiguration: PropTypes.shape({
    showDepartureTime: PropTypes.bool,
    showPlusMinusThreeDays: PropTypes.bool,
    showNonStop: PropTypes.bool,
    showAirlinePreference: PropTypes.bool,
    showOnlyFlexibleFlight: PropTypes.bool,
    showBookingClass: PropTypes.bool,
    showAlliancePreferences: PropTypes.bool,
    showOnlyBagIncludedFlight: PropTypes.bool,
    showExcludeCodeShareFlights: PropTypes.bool,
    whitelistedAirports: PropTypes.arrayOf(PropTypes.string),
  }),
  minHoursInFuture: PropTypes.number.isRequired,
  exchange: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
  }),
  selectAll: PropTypes.string,
  toggleSelectAll: PropTypes.func.isRequired,
  globalPolicyPreferences: PropTypes.shape().isRequired,
  preselectTravelers: PropTypes.arrayOf(
    PropTypes.shape({
      traveler: PropTypes.shape({
        value: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          middleName: PropTypes.string,
          lastName: PropTypes.string.isRequired,
          phoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
          emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
          age: PropTypes.number.isRequired,
        }),
      }),
    }),
  ).isRequired,
  setIsCollapsed: PropTypes.func,
  formWithoutChildren: PropTypes.bool.isRequired,
  selectGds: PropTypes.bool.isRequired,
  selectDirect: PropTypes.bool.isRequired,
  toggleSelectGds: PropTypes.func.isRequired,
  toggleSelectDirect: PropTypes.func.isRequired,
  resetBookingAppContext: PropTypes.func,
  travelArrangerPresent: PropTypes.bool.isRequired,
  travelerLoggedIn: PropTypes.bool.isRequired,
  airportSuggestions: PropTypes.shape(AirportShape).isRequired,
  isMultiCitySelected: PropTypes.bool,
  setIsMultiCitySelected: PropTypes.func,
  wtsEnabled: PropTypes.bool,
  isAdvancedForm: PropTypes.bool.isRequired,
  tripType: PropTypes.string.isRequired,
  setTripType: PropTypes.func.isRequired,
  isFromLastSearch: PropTypes.bool,
  setIsFromLastSearch: PropTypes.func.isRequired,
  canExcludeWtsContent: PropTypes.bool.isRequired,
  preselectDecision: PropTypes.string.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  updatePreselectTravelers: PropTypes.func.isRequired,
};

export default SearchFormComponent;
