import React, { useState } from 'react';
import PropTypes from 'prop-types';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import Alert from 'sharedWebpack/Alert';
import Input from '@wtag/rcl-input';
import itemShape from '../../../../admin/Orders/Show/ItemsTab/SetPaymentInformationSidePanel/shapes';
import ShareableLinks from './ShareableLinks';
import generateErrorMessages from './generateErrorMessages';
import isCopyContentDisabled from '../helpers/isCopyContentDisabled';
import copyTextToClipboard from '../helpers/copyTextToClipboard';
import getItemHeader from '../helpers/getItemHeader';
import getCartTitle from '../helpers/getCartTitle';
import prepareCartContent from '../helpers/prepareCartContent';
import { TYPE_STATIC_PRODUCT } from '../../../helpers/itemTypes';
import { COPY_CONTENT_TYPE, COPY_LINK_TYPE, SEND_EMAIL_TYPE } from '../helpers/constants/copyTypes';
import './styles.scss';

const CartShare = ({
  items,
  journeyElements,
  flightServicesByResultId,
  flightResultDetailsById,
  travelers,
  cartId,
  cartUrl,
  title,
  sharedTitle,
  callbackParams,
}) => {
  const [emails, setEmails] = useState([]);
  const [cartTitle, setCartTitle] = useState(sharedTitle || title || '');
  const [emailSent, setEmailSent] = useState(false);
  const [emailAddressSectionOpen, setEmailAddressSectionOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState({});
  const [isCartSharing, setIsCartSharing] = useState(false);
  const [isCartShared, setIsCartShared] = useState(false);
  const [shareType, setShareType] = useState('');
  const formattedEmails = emails.map(email => email.value);
  const disclaimerText = `\n---\n${I18n.t('components.ibe.search_results.disclaimer_text')}`;

  const getShareCartParams = isSharingCartProfile => {
    if (shareType === SEND_EMAIL_TYPE) {
      return {
        cart_title: cartTitle,
        share_profile_information: isSharingCartProfile,
        email_addresses: formattedEmails,
      };
    }

    if (shareType === COPY_LINK_TYPE) {
      return {
        cart_title: cartTitle,
        share_profile_information: isSharingCartProfile,
        callback_params: callbackParams,
      };
    }

    return {
      cart_title: cartTitle,
      share_profile_information: isSharingCartProfile,
      callback_params: callbackParams,
    };
  };

  const getCartContent = () => {
    let content = getCartTitle(travelers[0]);

    items.forEach((item, index) => {
      const { type, journeyElementId } = item;

      if (items.length > 1) {
        content += getItemHeader(index);
      }

      if (type === TYPE_STATIC_PRODUCT) {
        content += prepareCartContent(item);
      } else {
        const journeyElement = journeyElements[type][journeyElementId];
        content += prepareCartContent(item, journeyElement, travelers, flightServicesByResultId);
      }
    });

    content += disclaimerText;

    return content;
  };

  const clearInputFields = () => {
    setEmails([]);
  };

  const onCartShare = (type, isSharingCartProfile) => {
    setIsModalOpen(false);
    setIsCartSharing(true);

    const params = getShareCartParams(isSharingCartProfile);

    httpClient
      .put(routes.api.carts.share({ cartId }), params)
      .then(() => {
        setIsCartSharing(false);
        setIsCartShared(true);

        if (type === SEND_EMAIL_TYPE) {
          setEmailSent(true);
          setEmailAddressSectionOpen(false);
          clearInputFields();
        } else {
          const textToCopy = type === COPY_LINK_TYPE ? cartUrl : getCartContent();
          copyTextToClipboard(textToCopy);
        }

        setTimeout(() => {
          setIsCartShared(false);
        }, 2000);
      })
      .catch(({ response }) => {
        setIsCartSharing(false);
        setError(generateErrorMessages(response));
      });
  };

  const onShareableLinkClick = type => {
    setShareType(type);

    if (type !== COPY_CONTENT_TYPE) {
      setIsModalOpen(true);
    } else {
      onCartShare(type, true);
    }
  };

  const clearErrorOfType = type => {
    if (error[type]) {
      const newError = { ...error };
      delete newError[type];
      setError(newError);
    }
  };

  const onCartTitleChange = value => {
    setCartTitle(value);
    clearErrorOfType('cartTitle');
  };

  const onCreateEmailOptions = createdEmail => {
    setEmails(prevState => [...prevState, { label: createdEmail, value: createdEmail }]);
    clearErrorOfType('email');
  };

  const onEmailsChange = selectedEmails => {
    setEmails(selectedEmails || []);
    clearErrorOfType('email');
  };

  const onCancelEmailEdit = () => {
    setEmailAddressSectionOpen(false);
    setEmails([]);
    clearErrorOfType('email');
  };

  const onErrorAlertClose = () => {
    setError(prev => ({ ...prev, generic: '' }));
  };

  return (
    <div className="cart-share">
      <Alert
        className="cart-share__alert"
        isVisible={emailSent}
        type="success"
        onClose={() => setEmailSent(false)}
      >
        {I18n.t('cart.cart_share.email.sent')}
      </Alert>
      <div className="cart-share__title">{I18n.t('cart.cart_share.label')}</div>
      <div className="cart-share__description">{I18n.t('cart.cart_share.description')}</div>
      <div className="cart-share__cart-title">
        <div className="cart-share__cart-title-input">
          <Input
            label={I18n.t('cart.cart_share.cart_title_label')}
            size="tiny"
            placeholder={I18n.t('cart.cart_share.title.placeholder')}
            error={error.cartTitle}
            touched={!!error.cartTitle}
            value={cartTitle}
            onChange={onCartTitleChange}
            required={true}
          />
        </div>
      </div>
      <ShareableLinks
        emails={emails}
        cartTitle={cartTitle}
        emailAddressSectionOpen={emailAddressSectionOpen}
        error={error}
        onErrorAlertClose={onErrorAlertClose}
        setEmailAddressSectionOpen={setEmailAddressSectionOpen}
        onCreateEmailOptions={onCreateEmailOptions}
        onEmailsChange={onEmailsChange}
        onCancelEmailEdit={onCancelEmailEdit}
        isCopyContentDisabled={isCopyContentDisabled(items, flightResultDetailsById)}
        onShareableLinkClick={onShareableLinkClick}
        isCartSharing={isCartSharing}
        isCartShared={isCartShared}
        shareType={shareType}
      />
      <ConfirmationModal
        className="cart-share-modal"
        isModalOpen={isModalOpen}
        confirmationHeader={I18n.t('cart.cart_share.profile_share.header')}
        subHeader={I18n.t('cart.cart_share.profile_share.sub_header')}
        type="warning"
        withAction={true}
        onOutsideClick={() => {
          setIsCartSharing(false);
          setIsModalOpen(false);
        }}
        onConfirm={() => onCartShare(shareType, true)}
        onReject={() => onCartShare(shareType, false)}
      />
    </div>
  );
};

CartShare.defaultProps = {
  title: '',
  sharedTitle: '',
  flightResultDetailsById: null,
};

CartShare.propTypes = {
  items: PropTypes.arrayOf(itemShape).isRequired,
  journeyElements: PropTypes.shape({
    flight: PropTypes.shape({}),
    hotel: PropTypes.shape({}),
    car: PropTypes.shape({}),
  }).isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      type: PropTypes.string,
      birthday: PropTypes.string,
    }),
  ).isRequired,
  cartId: PropTypes.string.isRequired,
  cartUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  sharedTitle: PropTypes.string,
  callbackParams: PropTypes.shape().isRequired,
  flightServicesByResultId: PropTypes.shape({}).isRequired,
  flightResultDetailsById: PropTypes.shape({}),
};

export default CartShare;
