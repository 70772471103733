export const TYPE_ADULT = 'adult';
export const TYPE_CHILD = 'child';
export const TYPE_INFANT = 'infant';

export const CUSTOM_PASSENGER_TYPE_CODES = [
  { "code": "ACC", "name": "Accompanied person", "suppliers": ["amadeus"] },
  { "code": "GOV", "name": "GVT blended", "suppliers": ["amadeus"] },
  { "code": "GEX", "name": "Government exchange passenger", "suppliers": ["amadeus"] },
  { "code": "GDP", "name": "Government employee dependent", "suppliers": ["amadeus"] },
  { "code": "GCT", "name": "City/Council government passenger", "suppliers": ["amadeus"] },
  { "code": "GCF", "name": "Government contract", "suppliers": ["amadeus"] },
  { "code": "EMI", "name": "Emigrant", "suppliers": ["amadeus"] },
  { "code": "DOD", "name": "Dept of defense passenger", "suppliers": ["amadeus"] },
  { "code": "CPN", "name": "Coupon discount passenger", "suppliers": ["amadeus"] },
  { "code": "CMA", "name": "Adult with companion", "suppliers": ["amadeus"] },
  { "code": "CLG", "name": "Clergy", "suppliers": ["amadeus"] },
  { "code": "BUD", "name": "Airline buddy standby fare", "suppliers": ["amadeus"] },
  { "code": "BLD", "name": "Blind passenger", "suppliers": ["amadeus"] },
  { "code": "GVT", "name": "Government travel", "suppliers": ["amadeus"] },
  { "code": "GVM", "name": "GOV / GOVCT / CATZ", "suppliers": ["amadeus"] },
  { "code": "GST", "name": "State government", "suppliers": ["amadeus"] },
  { "code": "GTF", "name": "Government travel", "suppliers": ["amadeus"] },
  { "code": "MED", "name": "Patients travel for medical treatment", "suppliers": ["amadeus"] },
  { "code": "MCR", "name": "Military charter", "suppliers": ["amadeus"] },
  { "code": "MBT", "name": "Military - basic training graduate", "suppliers": ["amadeus"] },
  { "code": "LBR", "name": "Laborer / worker", "suppliers": ["amadeus"] },
  { "code": "JOB", "name": "Job corps", "suppliers": ["amadeus"] },
  { "code": "ITX", "name": "Individual inclusive tour passenger", "suppliers": ["amadeus"] },
  { "code": "IIT", "name": "Individual inclusive tour passenger", "suppliers": ["amadeus"] },
  { "code": "FTF", "name": "Frequent flyer", "suppliers": ["amadeus"] },
  { "code": "HCR", "name": "Adult charter", "suppliers": ["amadeus"] },
  { "code": "HOF", "name": "Head of family", "suppliers": ["amadeus"] },
  { "code": "MIS", "name": "Missionary", "suppliers": ["amadeus"] },
  { "code": "MIR", "name": "Military reserve on active duty", "suppliers": ["amadeus"] },
  { "code": "MIL", "name": "Military confirmed passenger", "suppliers": ["amadeus"] },
  { "code": "MFM", "name": "Military immediate family", "suppliers": ["amadeus"] },
  { "code": "NAT", "name": "NATO", "suppliers": ["amadeus"] },
  { "code": "MXS", "name": "Military DOD not based in USA", "suppliers": ["amadeus"] },
  { "code": "MSG", "name": "Multi state government", "suppliers": ["amadeus"] },
  { "code": "MUS", "name": "Military DOD based in USA", "suppliers": ["amadeus"] },
  { "code": "MSB", "name": "Military standby", "suppliers": ["amadeus"] },
  { "code": "MRE", "name": "Retired military and dependent", "suppliers": ["amadeus"] },
  { "code": "MPA", "name": "Military parents / parents in law", "suppliers": ["amadeus"] },
  { "code": "REC", "name": "Military recruit", "suppliers": ["amadeus"] },
  { "code": "PFC", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFB", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFA", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PCR", "name": "Adult charter", "suppliers": ["amadeus"] },
  { "code": "PFI", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFH", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFG", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFF", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFE", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFD", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFR", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFQ", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFP", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFO", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFN", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFM", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFL", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFK", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFJ", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFZ", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFY", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFX", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFW", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFV", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFU", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFT", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "PFS", "name": "Private fare", "suppliers": ["amadeus"] },
  { "code": "REF", "name": "Refugee", "suppliers": ["amadeus"] },
  { "code": "YTH", "name": "Youth confirmed", "suppliers": ["amadeus"] },
  { "code": "YSB", "name": "Youth standby", "suppliers": ["amadeus"] },
  { "code": "YCR", "name": "Youth charter", "suppliers": ["amadeus"] },
  { "code": "YCL", "name": "Clergy", "suppliers": ["amadeus"] },
  { "code": "YCD", "name": "Senior citizen", "suppliers": ["amadeus"] },
  { "code": "YCB", "name": "Senior citizen standby", "suppliers": ["amadeus"] },
  { "code": "VFR", "name": "Visit friends relatives", "suppliers": ["amadeus"] },
  { "code": "VAG", "name": "Group visit another country adult", "suppliers": ["amadeus"] },
  { "code": "VAC", "name": "Visitor another country adult", "suppliers": ["amadeus"] },
  { "code": "UNV", "name": "University employee", "suppliers": ["amadeus"] },
  { "code": "TUR", "name": "Tour conductor", "suppliers": ["amadeus"] },
  { "code": "STU", "name": "Student", "suppliers": ["amadeus"] },
  { "code": "SPS", "name": "Spouse", "suppliers": ["amadeus"] },
  { "code": "SEA", "name": "Seaman", "suppliers": ["amadeus"] },
  { "code": "SDB", "name": "Student standby", "suppliers": ["amadeus"] },
  { "code": "STR", "name": "State resident", "suppliers": ["amadeus"] },
  { "code": "SRC", "name": "Senior citizen", "suppliers": ["amadeus"] }
];
