import React, { Fragment } from 'react';
import Icon from '@wtag/rcl-icon';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import SelectBox from '@wtag/rcl-select-box';
import Alert from 'sharedWebpack/Alert';
import CopyLinkLabel from './CopyLinkLabel';
import { COPY_LINK_TYPE, COPY_CONTENT_TYPE, SEND_EMAIL_TYPE } from '../helpers/constants/copyTypes';

// Will be uncommented and used in a followup story
// const socials = [
//   { label: 'Facebook', icon: 'facebookRectangular' },
//   { label: 'WhatsApp', icon: 'whatsapp' },
//   { label: 'LinkedIn', icon: 'linkedinRectangular' },
//   { label: 'Twitter', icon: 'twitterRectangular' },
//   { label: 'Instagram', icon: 'instagramRectangular' },
//   { label: 'Slack', icon: 'slack' },
//   { label: 'Skype', icon: 'skype' },
//   { label: 'Discord', icon: 'discord' },
// ];

const ShareableLinks = ({
  cartTitle,
  emails,
  emailAddressSectionOpen,
  setEmailAddressSectionOpen,
  onEmailsChange,
  onCreateEmailOptions,
  error,
  onErrorAlertClose,
  onCancelEmailEdit,
  isCopyContentDisabled,
  onShareableLinkClick,
  isCartSharing,
  isCartShared,
  shareType,
}) => (
  <Fragment>
    {emailAddressSectionOpen ? (
      <EmailSection
        cartTitle={cartTitle}
        emails={emails}
        onEmailsChange={onEmailsChange}
        onShareableLinkClick={onShareableLinkClick}
        onCreateEmailOptions={onCreateEmailOptions}
        onCancelEmailEdit={onCancelEmailEdit}
        emailError={error.email}
      />
    ) : (
      <Fragment>
        <Alert
          type="danger"
          className="cart-share__error"
          isVisible={!!error.generic}
          onClose={onErrorAlertClose}
        >
          {error.generic}
        </Alert>
        <div className="d-flex flex-wrap justify-between cart-share__links">
          <Button
            className="cart-share__link-button"
            version="v2"
            size="normal"
            label={
              <CopyLinkLabel
                isCopiedToClipboard={isCartShared}
                isCopying={isCartSharing}
                text={I18n.t('cart.cart_share.copy_link_button')}
                isVisible={shareType === COPY_LINK_TYPE}
              />
            }
            icon={<Icon name="link" />}
            onClick={() => onShareableLinkClick(COPY_LINK_TYPE)}
          />
          <Button
            className="cart-share__link-button"
            version="v2"
            size="normal"
            label={
              <CopyLinkLabel
                isCopiedToClipboard={isCartShared}
                isCopying={isCartSharing}
                text={I18n.t('cart.cart_share.copy_content_button')}
                isVisible={shareType === COPY_CONTENT_TYPE}
              />
            }
            icon={<Icon name="copy" />}
            onClick={() => onShareableLinkClick(COPY_CONTENT_TYPE)}
            disabled={isCopyContentDisabled}
          />
          <Button
            className="cart-share__link-button"
            version="v2"
            size="normal"
            label={I18n.t('cart.cart_share.email_button')}
            icon={<Icon name="email" />}
            onClick={() => {
              setEmailAddressSectionOpen(true);
            }}
          />
          {/* Will be uncommented and used in a followup story */}
          {/* {socials.map(social => (
            <Button
              className="cart-share__link-button"
              version="v2"
              size="normal"
              key={social.label}
              label={social.label}
              icon={<Icon name={social.icon} />}
              disabled={true}
            />
          ))} */}
        </div>
      </Fragment>
    )}
  </Fragment>
);

ShareableLinks.defaultProps = {
  emails: [],
  error: {},
};

ShareableLinks.propTypes = {
  cartTitle: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
      .isRequired,
  ),
  emailAddressSectionOpen: PropTypes.bool.isRequired,
  setEmailAddressSectionOpen: PropTypes.func.isRequired,
  onCreateEmailOptions: PropTypes.func.isRequired,
  onEmailsChange: PropTypes.func.isRequired,
  onCancelEmailEdit: PropTypes.func.isRequired,
  error: PropTypes.shape({
    email: PropTypes.string,
    generic: PropTypes.string,
  }),
  onErrorAlertClose: PropTypes.func.isRequired,
  isCopyContentDisabled: PropTypes.bool.isRequired,
  onShareableLinkClick: PropTypes.func.isRequired,
  isCartSharing: PropTypes.bool.isRequired,
  isCartShared: PropTypes.bool.isRequired,
  shareType: PropTypes.string.isRequired,
};

const EmailSection = ({
  cartTitle,
  emails,
  onCreateEmailOptions,
  onEmailsChange,
  onShareableLinkClick,
  onCancelEmailEdit,
  emailError,
}) => (
  <div className="cart-share__links-email">
    <div className="cart-share__links-email-selectboxes">
      <SelectBox
        label={I18n.t('cart.cart_share.email.label')}
        placeholderText={I18n.t('cart.cart_share.email.placeholder')}
        size="small"
        isMulti={true}
        isCreatable={true}
        isClearable={false}
        value={emails}
        errorMsg={!!emailError && emailError}
        onChange={onEmailsChange}
        onCreateOption={onCreateEmailOptions}
        required={true}
        touched={!!emailError}
        width="full"
      />
    </div>
    <Button
      className="cart-share__links-email-share"
      version="v2"
      size="small"
      label={I18n.t('cart.cart_share.email.button')}
      type="primary"
      onClick={() => onShareableLinkClick(SEND_EMAIL_TYPE)}
      disabled={(!!emails && emails.length === 0) || cartTitle.length === 0}
    />
    <Button
      className="cart-share__links-email-cancel"
      version="v2"
      size="small"
      label={I18n.t('cart.cart_share.email.cancel')}
      type="secondary"
      onClick={onCancelEmailEdit}
    />
  </div>
);

EmailSection.defaultProps = {
  emails: [],
};

EmailSection.propTypes = {
  cartTitle: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
      .isRequired,
  ),
  onEmailsChange: PropTypes.func.isRequired,
  onCreateEmailOptions: PropTypes.func.isRequired,
  onCancelEmailEdit: PropTypes.func.isRequired,
  onShareableLinkClick: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
};

export default ShareableLinks;
