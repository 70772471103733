const getHotelTravelersInfo = travelers => {
  const travelersInfo =
    travelers &&
    travelers.length > 0 &&
    travelers
      .map(({ firstName, middleName, lastName, type }) => {
        const fullName = [firstName, middleName, lastName].filter(value => value).join(' ');
        if (!type) return fullName;

        return `${fullName} (${type.charAt(0).toUpperCase() + type.slice(1)})`;
      })
      .join(', ');

  return travelersInfo ? `${travelersInfo}\n` : '';
};

export default getHotelTravelersInfo;
