import 'react-dates/initialize';

import ReactOnRails from 'react-on-rails';

import 'sharedWebpack/application';
import TravelExpensesCreate from 'sharedWebpack/TravelExpensesTab/Create';
import TravelExpensesEdit from 'sharedWebpack/TravelExpensesTab/Edit';

import { Alert } from '@wtag/react-comp-lib';

import Dashboard from '../public/Dashboard';
import MobileApp from '../public/MobileApp';

import TopNav from '../public/TopNav';
import SubNav from '../public/SubNav';

import OrdersOverview from '../public/Orders/Overview';
import OrdersList from '../public/Orders/List';
import OrdersShow from '../public/Orders/Show';

import TravellersEditPage from '../public/Travellers/Edit';

import SignUpForm from '../public/Authentication/People';
import LoginForm from '../public/Authentication/Sessions';
import PasswordReset from '../public/Authentication/Passwords';
import EditPasswordForm from '../public/Authentication/Passwords/Edit';

import MidSection from '../public/MidSection';

import ApprovalRequestList from '../public/ApprovalRequest/List';
import ApprovalRequestShow from '../shared/ApprovalRequests/Show';

import CorporateTravelList from '../public/CorporateTravel/List';

import TravelArrangersList from '../public/TravelArrangers/List';
import TravelArrangersCreate from '../public/TravelArrangers/Create';
import TravelArrangersEdit from '../public/TravelArrangers/Edit';

import BookingApp from '../shared/IBE/IBEWrappedComponents';
import DealsCatalogue from '../public/Deals/DealsCatalogue';
import Testimonial from '../public/Testimonial';
import TopDeals from '../public/Deals/TopDeals';

import DocumentsCreate from '../public/Orders/Show/DocumentsTab/Create';
import DocumentsEdit from '../public/Orders/Show/DocumentsTab/Edit';

import AccountingCreate from '../admin/Orders/Show/AccountingTab/Create';
import AccountingEdit from '../admin/Orders/Show/AccountingTab/Edit';

import StaticPage from '../public/StaticPage';
import BlogPosts from '../public/BlogPosts/List';
import Footer from '../public/Footer';
import TopStories from '../public/shared/BlogPosts/TopStories';
import ImageSlider from '../public/shared/ImageSlider';
import FrontPage from '../public/FrontPage';
import AccessDeniedPage from '../public/AccessDeniedPage';

ReactOnRails.register({
  // NavBars
  'Public::TopNav': TopNav,
  'Public::SubNav': SubNav,

  // Dashboard
  'Public::Dashboard': Dashboard,

  // MobileApp
  'Public::MobileApp': MobileApp,

  // Travellers
  'Public::Travellers::Edit': TravellersEditPage,

  // Authentication
  'Public::Authentication::People': SignUpForm,
  'Public::Authentication::Sessions': LoginForm,
  'Public::Authentication::Passwords': PasswordReset,
  'Public::Authentication::Passwords::Edit': EditPasswordForm,

  // Orders
  'Public::Orders::List': OrdersList,
  'Public::Orders::Overview': OrdersOverview,
  'Public::Orders::Show': OrdersShow,
  'Public::Orders::Accounting::Create': AccountingCreate,
  'Public::Orders::Accounting::Edit': AccountingEdit,

  // Approval Request List
  'Public::ApprovalRequest::List': ApprovalRequestList,
  'Public::ApprovalRequest::Show': ApprovalRequestShow,

  // Corporate Trips
  'Public::CorporateTravel::List': CorporateTravelList,

  // Travel Arrangers
  'Public::TravelArrangers::List': TravelArrangersList,
  'Public::TravelArrangers::Create': TravelArrangersCreate,
  'Public::TravelArrangers::Edit': TravelArrangersEdit,

  // Documents
  'Public::Documents::Create': DocumentsCreate,
  'Public::Documents::Edit': DocumentsEdit,

  // Travel Expenses
  'Public::TravelExpenses::Create': TravelExpensesCreate,
  'Public::TravelExpenses::Edit': TravelExpensesEdit,

  // Static Pages
  'Public::StaticPage': StaticPage,
  'Public::BlogPosts': BlogPosts,
  // MidSection
  'Public::MidSection': MidSection,

  // Front Page
  'BlogPosts::TopStories': TopStories,
  'Public::shared::ImageSlider': ImageSlider,
  'Public::Footer': Footer,
  'Public::FrontPage': FrontPage,
  'Public::AccessDeniedPage': AccessDeniedPage,
});

ReactOnRails.register({ BookingApp });
ReactOnRails.register({ DealsCatalogue });
ReactOnRails.register({ Testimonial });
ReactOnRails.register({ TopDeals });
ReactOnRails.register({ Alert });
ReactOnRails.register({ StaticPage });
ReactOnRails.register({ BlogPosts });
