import React, { Fragment } from 'react';
import Input from '@wtag/rcl-input';
import PropTypes from 'prop-types';
import OrderList from 'sharedWebpack/Orders/List';
import Icon from '@wtag/rcl-icon';
import Modal from 'sharedWebpack/Modal';
import IconButton from '@wtag/rcl-icon-button';
import { ORDER_STYLES, BOOKING_STYLES } from '../../helpers/booking';
import BookingOption from '../BookingOption/';
import PayLaterInfo from '../PayLaterInfo/';
import ButtonWithSpinner from '../ButtonWithSpinner';
import { displayColumns, urlsPath, displaySortingPills, ordersPath } from '../constants';

const BookingModal = ({
  isModalOpen,
  onModalClose,
  modalHeader,
  createNewOrder,
  setOrderListOrderId,
  createQuote,
  orderTitle,
  onOrderTitleChange,
  orderStyle,
  orderId,
  error,
  loadIframe,
  payLaterItemsToBeQuoted,
  disableBooking,
  setLoadIframe,
  handleOrderStyleSelection,
  createOptionPossible,
  disabled: buttonDisabled,
  isQuoteButtonClicked,
}) => {
  const isBookingDisabled = Object.keys(error).length > 0;

  const stepOneOptions = [
    {
      iconName: 'add',
      text: I18n.t('components.ibe.check_out_progress.texts.new_order_text'),
      buttonLabel: (
        <ButtonWithSpinner
          text={I18n.t('components.ibe.check_out_progress.buttons.create_new_order')}
          isLoading={isQuoteButtonClicked}
        />
      ),
      onClick: createNewOrder,
    },

    {
      iconName: 'addOrder',
      text: I18n.t('components.ibe.check_out_progress.texts.select_order_choice'),
      buttonLabel: I18n.t('components.ibe.check_out_progress.buttons.add_to_existing_order'),
      onClick: () => handleOrderStyleSelection(ORDER_STYLES.EXISTING),
    },
  ];

  const stepTwoOptions = [
    {
      iconName: 'listBulleted',
      text: I18n.t('components.ibe.check_out_progress.texts.payment_choice_text'),
      buttonLabel: I18n.t('components.ibe.check_out_progress.buttons.quote'),
      disabled: isBookingDisabled,
      onClick: () => createQuote(),
      children: null,
    },

    {
      iconName: 'listNumbered',
      text: I18n.t('components.ibe.check_out_progress.texts.create_option_text'),
      buttonLabel: I18n.t('components.ibe.check_out_progress.buttons.create_option'),
      disabled: isBookingDisabled || !createOptionPossible || disableBooking,
      onClick: () => setLoadIframe(BOOKING_STYLES.OPTION),
      children:
        !createOptionPossible &&
        I18n.t('components.ibe.check_out_progress.texts.non_refundable_card_text'),
    },

    {
      iconName: 'orders',
      text: I18n.t('components.ibe.check_out_progress.texts.firm_booking_text'),
      buttonLabel: (
        <ButtonWithSpinner
          text={I18n.t('components.ibe.check_out_progress.buttons.create_booking')}
          isLoading={loadIframe || buttonDisabled}
        />
      ),
      disabled: isBookingDisabled || loadIframe || buttonDisabled || disableBooking,
      onClick: () => setLoadIframe(BOOKING_STYLES.REAL),
      children:
        loadIframe && I18n.t('components.ibe.check_out_progress.texts.authenticating_payments'),
    },
  ];

  const getContent = () => {
    let content;

    if (!orderStyle && !orderId) {
      content = (
        <div className="booking-modal__content">
          <BookingOption options={stepOneOptions} />
        </div>
      );
    } else if (orderStyle === ORDER_STYLES.EXISTING && !orderId) {
      content = (
        <OrderList
          ordersPath={ordersPath}
          displayColumns={displayColumns}
          displaySortingPills={displaySortingPills}
          hideArchived={true}
          lessPage={true}
          urls={urlsPath}
          setOrderId={id => setOrderListOrderId(id)}
        />
      );
    } else {
      content = (
        <div className="booking-modal__content">
          {!orderId && (
            <div className="booking-modal__order-title">
              <Input
                label={I18n.t('components.ibe.order.title')}
                placeholder={I18n.t('components.ibe.order.placeholder.title')}
                onChange={onOrderTitleChange}
                value={orderTitle}
                size="tiny"
                required={true}
                error={error?.orderTitle}
                touched={true}
              />
            </div>
          )}

          <BookingOption options={stepTwoOptions} />

          {payLaterItemsToBeQuoted?.length > 0 && (
            <PayLaterInfo
              disableBooking={disableBooking}
              payLaterItemsToBeQuoted={payLaterItemsToBeQuoted}
            />
          )}
        </div>
      );
    }

    return content;
  };

  return (
    <Modal
      className="booking-modal"
      title={
        <Fragment>
          {modalHeader}

          <IconButton
            isIconOnly={true}
            icon={<Icon name="close" />}
            color="tertiary"
            size="large"
            onClick={onModalClose}
          />
        </Fragment>
      }
      isModalVisible={isModalOpen}
      onOutsideClick={onModalClose}
      alignContent="left"
    >
      {getContent()}
    </Modal>
  );
};

BookingModal.defaultProps = {
  orderStyle: null,
  orderId: null,
  error: {},
};

BookingModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalHeader: PropTypes.string.isRequired,
  createNewOrder: PropTypes.func.isRequired,
  setOrderListOrderId: PropTypes.func.isRequired,
  onOrderTitleChange: PropTypes.func.isRequired,
  createQuote: PropTypes.func.isRequired,
  orderTitle: PropTypes.string.isRequired,
  orderStyle: PropTypes.string,
  orderId: PropTypes.string,
  error: PropTypes.shape(),
  loadIframe: PropTypes.bool.isRequired,
  setLoadIframe: PropTypes.func.isRequired,
  payLaterItemsToBeQuoted: PropTypes.arrayOf().isRequired,
  disableBooking: PropTypes.bool.isRequired,
  handleOrderStyleSelection: PropTypes.func.isRequired,
  createOptionPossible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isQuoteButtonClicked: PropTypes.bool.isRequired,
};

export default BookingModal;
