const generateErrorMessages = response => {
  const errorsList = {};
  const errors = response?.data?.errors || {};

  if (errors.cartTitle) {
    errorsList.cartTitle = I18n.t('cart.cart_share.error.cart_title');
  }

  if (errors.emailAddresses) {
    errorsList.email = I18n.t('cart.cart_share.error.email');
  }

  if (Object.keys(errorsList).length === 0) {
    errorsList.generic = I18n.t('cart.cart_share.error.generic');
  }

  return errorsList;
};

export default generateErrorMessages;
